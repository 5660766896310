import EmailTemplates from "../Components/Administration/EmailTemplates";
import EmailTemplatesEdit from "../Components/Administration/EmailTemplates/EmailTemplatesEdit";
import Forms from "../Components/Administration/Forms";
import Enrollment from "../Components/Administration/Forms/Enrollment";
import RoomLayout from "../Components/Administration/Forms/RoomLayout";
import Permissions from "../Components/Administration/Permissions";
import SystemLog from "../Components/Administration/SystemLog";
import Translations from "../Components/Administration/Translations";
/* import Profile from "../Components/Layout/Profile"; */
import EmailLog from "../Components/Administration/EmailLog";
import EmailLogView from "../Components/Administration/EmailLog/view";
import Campuses from "../Components/MasterData/Campuses";
import Buildings from "../Components/MasterData/Campuses/Buildings";
import BuildingsView from "../Components/MasterData/Campuses/Buildings/BuildingsView";
import Floors from "../Components/MasterData/Campuses/Buildings/Floors";
import FloorsView from "../Components/MasterData/Campuses/Buildings/Floors/FloorsView";
import Rooms from "../Components/MasterData/Campuses/Buildings/Floors/Rooms";
import Items from "../Components/MasterData/Campuses/Buildings/Floors/Rooms/Items";
import ItemsView from "../Components/MasterData/Campuses/Buildings/Floors/Rooms/Items/ItemsView";
import RoomsView from "../Components/MasterData/Campuses/Buildings/Floors/Rooms/RoomsView";
import CampusesView from "../Components/MasterData/Campuses/CampusesView";
import Semesters from "../Components/MasterData/Semesters";
import SemestersView from "../Components/MasterData/Semesters/SemestersView";
import Students from "../Components/MasterData/Students";
import Users from "../Components/MasterData/Users";
import UsersView from "../Components/MasterData/Users/UsersView";
import Main from "../Components/Modules/Main";
// import NewProcess from './../Components/Processes/NewProcess/index';
import Sync from "../Components/Administration/Sync";
import Documents from "../Components/MasterData/Campuses/Documents";
import DocumentsNew from "../Components/MasterData/Campuses/Documents/DocumentsNew";
import StudentsProfile from "../Components/MasterData/Students/StudentsProfile";
import TrainingLevels from "../Components/MasterData/TrainingLevels";
import TrainingLevelsView from "../Components/MasterData/TrainingLevels/TrainingLevelsView";
import TrainingPrograms from "../Components/MasterData/TrainingLevels/TrainingPrograms";
import TrainingProgramsView from "../Components/MasterData/TrainingLevels/TrainingPrograms/TrainingProgramsView";
import Universities from "../Components/MasterData/Universities";
import UniversitiesView from "../Components/MasterData/Universities/UniversitiesView";
import CampusSelector from "../Components/Modules/CampusSelector";
import ActiveCloseProcesses from "../Components/Processes/ActiveCloseProcesses";
import ProcessesDetails from "../Components/Processes/ActiveCloseProcesses/Details";
import EnrollmentTimeline from "../Components/Processes/ActiveCloseProcesses/EnrollmentTimeline";
import RelocationDetails from "../Components/Processes/ActiveCloseProcesses/RelocationDetails";
import StudentAllocationPreferenceDetails from "../Components/Processes/ActiveCloseProcesses/StudentAllocationPreferenceDetails";
import TerminationDetails from "../Components/Processes/ActiveCloseProcesses/TerminationDetails";
import FinancialControlEdit from "../Components/Processes/FinancialControl/FinancialControlEdit";
import NewProcess from "../Components/Processes/NewProcess";
import MoveOut from "../Components/Processes/NewProcess/Forms/MoveOut";
import RelocationMultiple from "../Components/Processes/NewProcess/Forms/RelocationMultiple";
import RelocationSingle from "../Components/Processes/NewProcess/Forms/RelocationSingle";
import NewTermination from "../Components/Processes/NewProcess/Forms/Termination";
import OperationalControl from "../Components/Processes/OperationalControl";
import ApproveRoomTakeOver from "../Components/Processes/Relocation/Forms/ApproveRoomTakeOver";
import ApproveUnpaidDebt from "../Components/Processes/Relocation/Forms/ApproveUnpaidDebt";
import RelocationFinancialControl from "../Components/Processes/Relocation/Forms/FinancialControl";
import MoveOutForm from "../Components/Processes/Relocation/Forms/MoveOutForm";
import OperatorRoomTakeOver from "../Components/Processes/Relocation/Forms/OperatorRoomTakeOver";
import StudentFilling from "../Components/Processes/Relocation/Forms/StudentFilling";
import StudentRoomTakeOver from "../Components/Processes/Relocation/Forms/StudentRoomTakeOver";
import SelectRelocationTypePage from "../Components/Processes/Relocation/SelectRelocationTypePage";
import RoomAcceptanceReport from "../Components/Processes/RoomAcceptanceReport";
import RoomAllocationList from "../Components/Processes/RoomAllocationList";
import SelectAllocationPageRooms from "../Components/Processes/RoomAllocationList/SelectAllocationPageRooms";
import RoomApproval from "../Components/Processes/RoomApproval";
import StudentAllocationPreferenceForm from "../Components/Processes/StudentAllocationPreferenceForm";
import TerminationFinancialCheck from "../Components/Processes/Termination/FinancialCheck";
import TerminationOperatorCheck from "../Components/Processes/Termination/OperatorCheck";
import StudentDeclaration from "../Components/Processes/Termination/StudentDeclaration";
import RelocationsList from "../Components/Reports/RelocationsList";
import MoveoutList from "../Components/Reports/MoveoutList";
import StudentAllocationPreferenceList from "../Components/Reports/StudentAllocationPreferenceList";
import SelectAllocationPageStudents from "../Components/Reports/StudentAllocationPreferenceList/SelectAllocationPageStudents";
import StudentsList from "../Components/Reports/StudentsList";
import TerminationsList from "../Components/Reports/TerminationsList";
import Approval from "./../Components/Processes/Approval/index";
import FinancialControl from "./../Components/Processes/FinancialControl/index";
import NewEnrollment from "./../Components/Processes/NewProcess/Forms/Enrollment";
import StudentAllocationPreference from "./../Components/Processes/NewProcess/Forms/StudentAllocationPreference";
import { PERMISSIONS, PERMISSIONS_WITH_USER } from "./AppConst";

export const routes = [
    { path: "/", component: Main, roles: Object.values(PERMISSIONS_WITH_USER) },
    { path: "/translations", component: Translations },
    { path: "/email-templates", component: EmailTemplates },
    { path: "/email-templates/edit/:id", component: EmailTemplatesEdit },
    { path: "/email-log", component: EmailLog },
    { path: "/email-log/view/:id", component: EmailLogView },
    { path: "/system-log", component: SystemLog },
    { path: "/users", component: Users },
    { path: "/users/view/:id", component: UsersView },
    {
        path: "/campuses",
        component: Campuses,
        roles: [PERMISSIONS.OPERATOR_ADMIN, PERMISSIONS.SYSTEM_ADMIN],
    },
    { 
        path: "/campuses/view/:id",
        component: CampusesView,
        roles: [PERMISSIONS.OPERATOR_ADMIN, PERMISSIONS.SYSTEM_ADMIN],
    },
    { path: "/campuses/:campusId/documents", component: Documents },
    { path: "/campuses/:campusId/documents/new", component: DocumentsNew },
    {
        path: "/campuses/:campusId/buildings",
        component: Buildings,
        roles: [PERMISSIONS.OPERATOR_ADMIN, PERMISSIONS.SYSTEM_ADMIN],
    },
    {
        path: "/campuses/:campusId/buildings/view/:id",
        component: BuildingsView,
        roles: [PERMISSIONS.OPERATOR_ADMIN, PERMISSIONS.SYSTEM_ADMIN],
    },
    {
        path: "/campuses/:campusId/buildings/:buildingId/floors",
        component: Floors,
        roles: [PERMISSIONS.OPERATOR_ADMIN, PERMISSIONS.SYSTEM_ADMIN],
    },
    {
        path: "/campuses/:campusId/buildings/:buildingId/floors/view/:id",
        component: FloorsView,
        roles: [PERMISSIONS.OPERATOR_ADMIN, PERMISSIONS.SYSTEM_ADMIN],
    },
    {
        path: "/campuses/:campusId/buildings/:buildingId/floors/:floorId/rooms",
        component: Rooms,
        roles: [PERMISSIONS.OPERATOR_ADMIN, PERMISSIONS.SYSTEM_ADMIN],
    },
    {
        path: "/campuses/:campusId/buildings/:buildingId/floors/:floorId/rooms/view/:id",
        component: RoomsView,
        roles: [PERMISSIONS.OPERATOR_ADMIN, PERMISSIONS.SYSTEM_ADMIN],
    },
    {
        path: "/campuses/:campusId/buildings/:buildingId/floors/:floorId/rooms/:roomId/items/",
        component: Items,
        roles: [PERMISSIONS.OPERATOR_ADMIN, PERMISSIONS.SYSTEM_ADMIN],
    },
    {
        path: "/campuses/:campusId/buildings/:buildingId/floors/:floorId/rooms/:roomId/items/view/:id",
        component: ItemsView,
        roles: [PERMISSIONS.OPERATOR_ADMIN, PERMISSIONS.SYSTEM_ADMIN],
    },
    { path: "/semesters", component: Semesters },
    { path: "/semesters/view/:id", component: SemestersView },
    { path: "/universities", component: Universities },
    { path: "/universities/view/:id", component: UniversitiesView },
    { path: "/training-levels", component: TrainingLevels },
    { path: "/training-levels/view/:id", component: TrainingLevelsView },
    {
        path: "/training-levels/:trainingLevelId/training-programs",
        component: TrainingPrograms,
    },
    {
        path: "/training-levels/:trainingLevelId/training-programs/view/:id",
        component: TrainingProgramsView,
    },
    { path: "/forms", component: Forms },
    { path: "/forms/enrollment", component: Enrollment },
    {
        path: "/forms/enrollment/:id",
        component: Enrollment,
        roles: [PERMISSIONS.UNIVERSITY_ADMIN, PERMISSIONS.STUDENT],
    },
    {
        path: "/forms/approval/:id",
        component: Approval,
        roles: [PERMISSIONS.UNIVERSITY_ADMIN],
    },
    {
        path: "/forms/room-approval/:id",
        component: RoomApproval,
        roles: [PERMISSIONS.UNIVERSITY_ADMIN],
    },
    { path: "/forms/room-layout", component: RoomLayout },
    { path: "/students", component: Students },
    { path: "/students/profile/:id", component: StudentsProfile },
    /* { path: "/profile", component: Profile, roles: Object.values(PERMISSIONS) }, */
    { path: "/permissions", component: Permissions },
    {
        path: "/new-process",
        component: NewProcess,
        roles: [PERMISSIONS.UNIVERSITY_ADMIN],
    },
    {
        path: "/new-process/enrollment",
        component: NewEnrollment,
        roles: [PERMISSIONS.UNIVERSITY_ADMIN],
    },
    { path: "/financial-control", component: FinancialControl },
    {
        path: "/financial-control/edit/:id",
        component: FinancialControlEdit,
        roles: [PERMISSIONS.FINANCIAL_ADMIN],
    },
    {
        path: "/forms/operational-control/:id",
        component: OperationalControl,
        roles: [PERMISSIONS.OPERATOR_ADMIN],
    },
    {
        path: "/forms/room-acceptance-report/:id",
        component: RoomAcceptanceReport,
        roles: [PERMISSIONS.STUDENT],
    },
    {
        path: "/campus-selector",
        component: CampusSelector,
        roles: [PERMISSIONS.SYSTEM_ADMIN, PERMISSIONS.FINANCIAL_ADMIN],
    },

    {
        path: "/processes",
        component: ActiveCloseProcesses,
        roles: [PERMISSIONS.UNIVERSITY_ADMIN],
    },
    {
        path: "/processes/details/:id",
        component: ProcessesDetails,
        roles: [PERMISSIONS.UNIVERSITY_ADMIN],
    },
    {
        path: "/processes/student-allocation-preference/details/:id",
        component: StudentAllocationPreferenceDetails,
        roles: [PERMISSIONS.UNIVERSITY_ADMIN],
    },
    {
        path: "/processes/relocation/details/:id",
        component: RelocationDetails,
        roles: [PERMISSIONS.UNIVERSITY_ADMIN],
    },
    {
        path: "/processes/termination/details/:id",
        component: TerminationDetails,
        roles: [PERMISSIONS.UNIVERSITY_ADMIN],
    },
    {
        path: "/processes/move-out/details/:id",
        component: TerminationDetails,
        roles: [PERMISSIONS.UNIVERSITY_ADMIN],
    },
    {
        path: "/processes/timeline/:id",
        component: EnrollmentTimeline,
        roles: [PERMISSIONS.UNIVERSITY_ADMIN],
    },

    {
        path: "/new-process/student-allocation-preference",
        component: StudentAllocationPreference,
        roles: [PERMISSIONS.UNIVERSITY_ADMIN],
    },
    {
        path: "/student-allocation-preference-form/:id",
        component: StudentAllocationPreferenceForm,
        roles: [PERMISSIONS.SYSTEM_ADMIN, PERMISSIONS.STUDENT],
    },

    /**
     * Termination - Kiiratkozás
     */
    {
        path: "/new-process/termination",
        component: NewTermination,
        roles: [PERMISSIONS.UNIVERSITY_ADMIN],
    },
    {
        path: "/forms/termination/student-declaration/:id",
        component: StudentDeclaration,
        roles: [PERMISSIONS.STUDENT],
    },
    {
        path: "/forms/termination/operator-check/:id",
        component: TerminationOperatorCheck,
        roles: [PERMISSIONS.OPERATOR_ADMIN],
    },
    {
        path: "/forms/termination/financial-check/:id",
        component: TerminationFinancialCheck,
        roles: [PERMISSIONS.FINANCIAL_ADMIN],
    },
    /**
     * MoveOut - Kiköltözés
     */
    {
        path: "/new-process/move-out",
        component: MoveOut,
        roles: [PERMISSIONS.UNIVERSITY_ADMIN],
    },

    /**
     * Relocation - Átköltözés
     */
    {
        path: "/new-process/relocation",
        component: SelectRelocationTypePage,
        roles: [PERMISSIONS.UNIVERSITY_ADMIN],
    },
    {
        path: "/new-process/relocation/single",
        component: RelocationSingle,
        roles: [PERMISSIONS.UNIVERSITY_ADMIN],
    },
    {
        path: "/new-process/relocation/multiple",
        component: RelocationMultiple,
        roles: [PERMISSIONS.UNIVERSITY_ADMIN],
    },
    {
        path: "/forms/relocation/student-filling/:id",
        component: StudentFilling,
        roles: [PERMISSIONS.STUDENT],
    },
    {
        path: "/forms/relocation/financial-control/:id",
        component: RelocationFinancialControl,
        roles: [PERMISSIONS.FINANCIAL_ADMIN],
    },
    {
        path: "/forms/relocation/move-out-form/:id",
        component: MoveOutForm,
        roles: [PERMISSIONS.STUDENT],
    },
    {
        path: "/forms/relocation/operator-room-take-over/:id",
        component: OperatorRoomTakeOver,
        roles: [PERMISSIONS.OPERATOR_ADMIN],
    },
    {
        path: "/forms/relocation/student-room-take-over/:id",
        component: StudentRoomTakeOver,
        roles: [PERMISSIONS.STUDENT],
    },
    {
        path: "/forms/relocation/approve-room-take-over/:id",
        component: ApproveRoomTakeOver,
        roles: [PERMISSIONS.OPERATOR_ADMIN],
    },
    {
        path: "/forms/relocation/approve-unpaid-debt/:id",
        component: ApproveUnpaidDebt,
        roles: [PERMISSIONS.FINANCIAL_ADMIN],
    },

    {
        path: "/room-allocation-list/",
        component: SelectAllocationPageRooms,
        roles: [PERMISSIONS.SYSTEM_ADMIN],
    },
    {
        path: "/room-allocation-list/:id",
        component: RoomAllocationList,
        roles: [PERMISSIONS.SYSTEM_ADMIN],
    },

    {
        path: "/student-allocation-preference-list",
        component: SelectAllocationPageStudents,
        roles: [PERMISSIONS.SYSTEM_ADMIN],
    },
    {
        path: "/student-allocation-preference-list/:id",
        component: StudentAllocationPreferenceList,
        roles: [PERMISSIONS.SYSTEM_ADMIN],
    },
    {
        path: "/enrollments-list",
        component: StudentsList,
        roles: [PERMISSIONS.SYSTEM_ADMIN],
    },
    {
        path: "/terminations-list",
        component: TerminationsList,
        roles: [PERMISSIONS.SYSTEM_ADMIN],
    },
    {
        path: "/moveout-list",
        component: MoveoutList,
        roles: [PERMISSIONS.SYSTEM_ADMIN],
    },
    { path: "/sync", component: Sync, roles: [PERMISSIONS.SYSTEM_ADMIN] },
    {
        path: "/student-allocation-preference-list",
        component: SelectAllocationPageStudents,
        roles: [PERMISSIONS.SYSTEM_ADMIN],
    },
    {
        path: "/student-allocation-preference-list/:id",
        component: StudentAllocationPreferenceList,
        roles: [PERMISSIONS.SYSTEM_ADMIN],
    },
    {
        path: "/relocations-list",
        component: RelocationsList,
        roles: [PERMISSIONS.SYSTEM_ADMIN],
    }
];
