import { relocationsList } from './relocationsList/reducer';
import { moveoutList } from './moveoutList/reducer';
import { studentAllocationPreferenceList } from './studentAllocationPreferenceList/reducer';
import { studentsList } from './studentsList/reducer';
import { terminationsList } from './terminationsList/reducer';

export const reports = {
    studentAllocationPreferenceList,
    studentsList,
    relocationsList,
    terminationsList,
    moveoutList,
}