import axios from "axios";
import { useEffect } from "react";
import { connect } from "react-redux";
import loadingActions from "../../../store/loading/actions";
import campusesActions from "../../../store/masterData/campuses/actions";
import collegeYearsActions from "../../../store/masterData/collegeYears/actions";
import semestersActions from "../../../store/masterData/semesters/actions";
import trainingLevelsActions from "../../../store/masterData/trainingLevels/actions";
import trainingProgramsActions from "../../../store/masterData/trainingPrograms/actions";
import notificationActions from "../../../store/notification/actions";
import studentsListActions from "../../../store/reports/studentsList/actions";
import instance from "../../../utils/API";
import { options } from "../../../utils/AppConst";
import AddressCellRenderer from "../../../utils/cellRenderer/AddressCellRenderer";
import MotherNameCellRenderer from "../../../utils/cellRenderer/NameCellRenderer";
import { setInfo } from "../../../utils/FilterHelper";
import { filterStorageSave } from "../../../utils/FilterStorage";
import { makeOptionsFromID, makeOptionsFromIDWithArchive, makeTrainingProgramOptionsFromIDWithArchive } from "../../../utils/listOptions/makeOptionsFromID";
import { downloadFile } from "../../Administration/Forms/Enrollment/utils";
import ListPageTemplate from "../../Common/ListPageTemplate";
import OperationButton from "../../Common/OperationButton";
import Select from "../../Common/Select";
import TextField from "../../Common/TextField";
import { renderArchiveOption } from "../../MasterData/Students/options";

function StudentsList(props) {
    const { filter, data, fields, trainingLevels, trainingPrograms, collegeYears, campuses, semesters, filterStorageKey, tableInfo } = props;
    let activeSemesters = [];
    const active = props.semesters["hydra:member"].filter((elem) => elem.active);
    
    useEffect(() => {
        if (active.length) {
            activeSemesters = active.map((elem) => elem.id);
        } else if (props.semesters.length && "id" in props.semesters[0]) {
            activeSemesters = [props.semesters[0].id ?? null];
        }
        props.changeForm({ semester: activeSemesters}, "filter");
        props.loadingOn();
        axios.all([
            props.fetchCampuses(),
            props.fetchTrainingLevels(),
            props.fetchTrainingPrograms(),
            props.fetchCollegeYears(),
            props.fetchSemesters(),
            setInfo({ ...props, search: props.search }, "order", { semester: "desc" }, "filter", { semester: activeSemesters}),
        ]).then(() => props.loadingOff())
            .catch(() => props.loadingOff());
    }, []);

    const header = {
        title: "Beiratkozások",
        breadcrumbs: {
            "reports": "Riportok",
            "students": "Beiratkozások"
        }
    }

    const filterForm = {
        submitaction: (filter) => {
            filterStorageSave(filterStorageKey, filter);
            setInfo(props, "filter", filter)
        },
        defaultFormAction: () => {
            if (active.length) {
                activeSemesters = active.map((elem) => elem.id);
            } else if (props.semesters.length && "id" in props.semesters[0]) {
                activeSemesters = [props.semesters[0].id ?? null];
            }
            props.defaultForm("filter");
            props.changeForm({ semester: activeSemesters }, "filter");
            filterStorageSave(filterStorageKey, { semester: activeSemesters });
            setInfo(props, "filter", { semester: activeSemesters });
        },
        form: filter,
        changeform: props.changeForm
    }

    const handleDownloadUniversityAdmissionDecision = (params) => {
        props.addNotification("info", "A fájl letöltése folyamatban...");

        if (params.value) {
            instance.get(`/enrollments/${params.data.id}/fill-student/university-study/${params.value}/download-file`, {responseType: 'blob'})
            .then((response) => {
                downloadFile(response.data, `${params.data.fullName}_felvételi_határozat.pdf`);
                props.addNotification("success", "Sikeres letöltés!")
            }).catch((error) => {
                const errorMessage = error.response.status === 404 ? "A fájl nem található" : "Hiba a letöltés során";
                props.addNotification("error", errorMessage);
            });
        } else {
            const errorMessage = "Nincs feltöltött fájl";
            props.addNotification("error", errorMessage);
        }
    };

    function BtnCellRenderer(params) {
        if (params.value) {
            return (
                <>
                    <OperationButton
                        type="download"
                        onClick={() => handleDownloadUniversityAdmissionDecision(params)}
                    />
                </>
            );
        } else {
            return "Nincs fájl feltöltve"
        }
    }

    const downloadExportAction = () => {
        let date = new Date().toISOString().split('T')[0];
        let fileName = `Beiratkozasok_riport_${date}.xlsx`;
        props.addNotification("info", "Fájl letöltése folyamatban...");
        props.downloadExport(filter, fileName)
            .then(() => props.addNotification("success", "Fájl letöltése sikeres!"))
            .catch(() => props.addNotification("error", "Hiba lépett fel a fájl letöltése közben."));
    }

    const table = {
        columnDefs: [
            { headerName: "Sorszám", field: "displayId" },
            { headerName: "Szemeszter", field: "semester" },
            { headerName: "Folyamatstátusz", field: "status" },
            { headerName: "Vezetéknév", field: "lastName" },
            { headerName: "Keresztnév", field: "firstName" },
            { headerName: "Nem", field: "gender" },
            { headerName: "E-mail cím", field: "email" },
            { headerName: "Telefonszám", field: "phoneNumber" },
            { headerName: "Képzési szint", field: "trainingLevel" },
            { headerName: "Képzési program", field: "trainingProgram", minWidth: 300 },
            { headerName: "Évfolyam", field: "collegeYear" },
            { headerName: "Szemeszter státusz", field: "semesterStatus" },
            { headerName: "Státusz", field: "membershipStatus" },
            { headerName: "Aktuális státusz", field: "currentMembershipStatus" },
            { headerName: "Campus", field: "campus" },
            { headerName: "Épület", field: "building" },
            { headerName: "Szint", field: "floor" },
            { headerName: "Szoba", field: "room" },
            { headerName: "Cím", field: "address", cellRenderer: "addressCellRenderer", minWidth: 400, sortable: false },
            { headerName: "OM azonosító", field: "omId" },
            { headerName: "Adóazonosító jel", field: "taxId" },
            { headerName: "TAJ szám", field: "taj" },
            { headerName: "SZIG szám", field: "idCardNumber" },
            { headerName: "Személyi szám (CNP)", field: "identificationNumberCnp" },
            { headerName: "SZIG szám - KEP", field: "kepIdCardNumber" },
            { headerName: "Pénzintézet neve", field: "bankName" },
            { headerName: "Bankszámlaszám", field: "bankAccountNumber" },
            { headerName: "Születési hely", field: "birthPlace" },
            { headerName: "Születési idő", field: "birthDate" },
            { headerName: "Állampolgárság", field: "nationality" },
            { headerName: "Anyja vezetékneve", field: "motherLastName" },
            { headerName: "Anyja keresztneve", field: "motherFirstName" },
            { headerName: "Egyetem 1", field: "university1", minWidth: 400 },
            { headerName: "Szak", field: "universityMajor1" },
            { headerName: "Képzés formája", field: "trainingForm1" },
            { headerName: "Finanszírozás formája", field: "financingForm1" },
            { headerName: "Hallgatói jogviszony", field: "semesterStatus1" },
            { headerName: "Neptun kód", field: "neptun1" },
            {
                headerName: "Egyetemi felvételi határozat",
                field: "fileId1",
                cellRenderer: "btnCellRenderer",
                sortable: false
            },
            { headerName: "Egyetem 2", field: "university2", minWidth: 400 },
            { headerName: "Szak", field: "universityMajor2" },
            { headerName: "Képzés formája", field: "trainingForm2" },
            { headerName: "Finanszírozás formája", field: "financingForm2" },
            { headerName: "Hallgatói jogviszony", field: "semesterStatus2" },
            { headerName: "Neptun kód", field: "neptun2" },
            {
                headerName: "Egyetemi felvételi határozat",
                field: "fileId2",
                cellRenderer: "btnCellRenderer",
                sortable: false
            },
            { headerName: "Egyetem 3", field: "university3", minWidth: 400 },
            { headerName: "Szak", field: "universityMajor3" },
            { headerName: "Képzés formája", field: "trainingForm3" },
            { headerName: "Finanszírozás formája", field: "financingForm3" },
            { headerName: "Hallgatói jogviszony", field: "semesterStatus3" },
            { headerName: "Neptun kód", field: "neptun3" },
            {
                headerName: "Egyetemi felvételi határozat",
                field: "fileId3",
                cellRenderer: "btnCellRenderer",
                sortable: false
            }
        ].filter(column => fields?.includes(column.field)),
        rowData: data,
        frameworkComponents: {
            addressCellRenderer: AddressCellRenderer,
            nameCellRenderer: MotherNameCellRenderer,
            btnCellRenderer: BtnCellRenderer
        },
        minWidth: 200,
        download: true,
        onClick: downloadExportAction,
        searchfunc: props.search,
        info: tableInfo,
        setInfo: props.setInfo,
        isPaginated: true,
        showPagination: false
    }

    return (
        <ListPageTemplate
            table={table}
            filter={filterForm}
            header={header}
        >
            <TextField
                label="E-mail cím"
                name="email"
                value={filter.email || ""}
                format={{ xs: 12, md: 4 }}
            />
            <TextField
                label="Vezetéknév"
                name="lastName"
                value={filter.lastName || ""}
                format={{ xs: 12, md: 4 }}
            />
            <TextField
                label="Keresztnév"
                name="firstName"
                value={filter.firstName || ""}
                format={{ xs: 12, md: 4 }}
            />
            <Select
                selectLabel="Campus"
                name="campus"
                value={filter.campus || []}
                optionList={makeOptionsFromID(campuses) || []}
                format={{ xs: 12, md: 4 }}
                multiple={true}
            />
            <Select
                selectLabel="Képzési szint"
                name="trainingLevel"
                value={filter.trainingLevel || []}
                optionList={makeOptionsFromIDWithArchive(trainingLevels) || []}
                format={{ xs: 12, md: 4 }}
                multiple={true}
                renderOption={(option) => renderArchiveOption(option)}
            />
            <Select
                selectLabel="Képzési program"
                name="trainingProgram"
                value={filter.trainingProgram || []}
                optionList={makeTrainingProgramOptionsFromIDWithArchive(trainingPrograms) || []}
                format={{ xs: 12, md: 4 }}
                multiple={true}
                renderOption={(option) => renderArchiveOption(option)}
            />
            <Select
                selectLabel="Évfolyam"
                name="collegeYear"
                value={filter.collegeYear || []}
                optionList={makeOptionsFromIDWithArchive(collegeYears) || []}
                format={{ xs: 12, md: 4 }}
                multiple={true}
                renderOption={(option) => renderArchiveOption(option)}
            />
            <Select
                selectLabel="Szemeszter"
                name="semester"
                value={filter.semester || []}
                optionList={makeOptionsFromID(semesters) || []}
                format={{ xs: 12, md: 4 }}
                multiple={true}
            />
            <Select
                selectLabel="Folyamatstátusz"
                name="status"
                value={filter.status || []}
                optionList={options.processStatusOptions}
                format={{ xs: 12, md: 4 }}
                multiple={true}
            />
            <Select
                selectLabel="Szemeszter státusz"
                name="semesterStatus"
                value={filter.semesterStatus || []}
                optionList={options.semesterStatusOptions}
                format={{ xs: 12, md: 6 }}
                multiple={true}
            />
            <Select
                selectLabel="Aktuális státusz"
                name="currentMembershipStatus"
                value={filter.currentMembershipStatus || []}
                optionList={options.statusOptions}
                format={{ xs: 12, md: 6 }}
                multiple={true}
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, fields, filterStorageKey, tableInfo } = state.studentsList;
    const trainingLevels = state.trainingLevels.data;
    const trainingPrograms = state.trainingPrograms.data;
    const collegeYears = state.collegeYears.data;
    const campuses = state.campuses.data;
    const semesters = state.semesters.data;
    return { filter, data, fields, filterStorageKey, tableInfo, trainingLevels, trainingPrograms, collegeYears, campuses, semesters };
}

const actionCreators = {
    changeForm: studentsListActions.changeForm,
    defaultForm: studentsListActions.defaultForm,
    downloadExport: studentsListActions.downloadExport,
    setInfo: studentsListActions.setInfo,
    donwloadUniversityAdmissionDecision: studentsListActions.donwloadUniversityAdmissionDecision,
    search: studentsListActions.filter,
    fetchCampuses: campusesActions.filter,
    fetchTrainingLevels: trainingLevelsActions.filter,
    fetchTrainingPrograms: trainingProgramsActions.fetch,
    fetchCollegeYears: collegeYearsActions.fetch,
    fetchSemesters: semestersActions.filter,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(StudentsList);