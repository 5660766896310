import API from '../../../utils/API';
import SearchForm from "../../../utils/SearchForm";
import downloadAPI from '../../../utils/downloadAPI';


function filter(form) {
    const searchForm = new SearchForm(form);

    return API.get('/relocations/report', {params: searchForm.getSend()})
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

function downloadExport(filter) {
    const searchForm = new SearchForm(filter);

    return downloadAPI.get(`/relocations/report/export`, {params: searchForm.getSend()})
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}


const relocationsListService = {
    filter,
    downloadExport
};

export default relocationsListService;
