import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import { useEffect } from "react";
import colors from "../../../styles/colors";
import Select from "../../Common/Select";

/**
 * Creates an array of options for the Select component, based on the given amount.
 *
 * @param {number} amount The maximum amount for the options.
 * @returns {Array} An array of objects containing value and label properties.
 */
const createItemOptions = (amount) => {
    let options = [];
    for (let i = 0; i <= amount; i++) {
        options.push({
            value: `${i}`,
            label: `${i}`,
        });
    }
    return options;
};

/**
 * Custom styles for the InventoryTable component.
 */
const useInventoryTableStyles = makeStyles(() => ({
    tableCell: {
        fontSize: "12px",
        color: "#0000008A",
        fontWeight: "600",
        whiteSpace: "nowrap",
    },
    headerCell: {
        fontSize: "12px",
        color: colors.primaryBlue,
        fontWeight: "1000",
        whiteSpace: "nowrap",
    },
    acceptanceText: {
        fontSize: "14px",
        padding: "8px 8px 8px 4px",
        fontWeight: "bold",
    },
}));

/**
 * RoomInventoryTable component displays a table of room inventory items
 * and their corresponding amount values.
 *
 * @component
 * @example
 * return (
 *   <RoomInventoryTable
 *     form={form}
 *     room={room}
 *     changeForm={changeForm}
 *   />
 * );
 */
function RoomInventoryTable({
    form,
    room,
    changeForm,
    readOnly,
}) {
    const classes = useInventoryTableStyles();
    useEffect(() => {
        if (room?.items?.length) {
            const updatedItems = {};
            room.items.forEach((item) => {
                updatedItems[item.id] = item.amount || 0;
            });

            changeForm({
                ...form,
                items: updatedItems,
            });
        }
    }, []); 

    if (!room || !room?.items || room?.items?.length === 0) {
        return (
            <Typography className={classes.acceptanceText}>
                A szobához a leltár szerint nem tartozik tárgy.
            </Typography>
        );
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.headerCell}>Név</TableCell>
                    <TableCell className={classes.headerCell}>
                        Ellenőrzés során
                    </TableCell>
                    <TableCell className={classes.headerCell}>
                        Leltár szerint
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {room?.items?.map((item) => (
                    <TableRow key={item.id}>
                        <TableCell className={classes.tableCell}>
                            {item.name}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            {readOnly ? (
                                form?.takenOverItems?.find(
                                    //FIXME: itt taken, handedover nem mindegy !!!
                                    (x) => x.item?.id === item.id
                                )?.amount || item.amount || 0
                            ) : (
                                <Select
                                    name={`items.${item.id}`}
                                    value={form.items?.[item?.id] || item.amount ||  "0"}
                                    optionList={createItemOptions(item.amount)}
                                    format={{ xs: 12 }}
                                    functionToChild={changeForm}
                                    formToChild={form}
                                    formNameToChild="form"
                                />
                            )}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            {item.amount}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export default RoomInventoryTable;
