import axios from "axios";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import loadingActions from "../../../store/loading/actions";
import campusesActions from "../../../store/masterData/campuses/actions";
import collegeYearsActions from "../../../store/masterData/collegeYears/actions";
import semestersActions from "../../../store/masterData/semesters/actions";
import trainingLevelsActions from "../../../store/masterData/trainingLevels/actions";
import trainingProgramsActions from "../../../store/masterData/trainingPrograms/actions";
import notificationActions from "../../../store/notification/actions";
import terminationsListActions from "../../../store/reports/terminationsList/actions";
import { options } from "../../../utils/AppConst";
import MotherNameCellRenderer from "../../../utils/cellRenderer/NameCellRenderer";
import { setInfo } from "../../../utils/FilterHelper";
import { filterStorageSave } from "../../../utils/FilterStorage";
import { makeOptionsFromID } from "../../../utils/listOptions/makeOptionsFromID";
import ListPageTemplate from "../../Common/ListPageTemplate";
import Select from "../../Common/Select";
import TextField from "../../Common/TextField";

function TerminationsList(props) {
    const { filter, data, fields, campuses, trainingLevels, trainingPrograms, collegeYears, semesters, filterStorageKey, tableInfo } = props;
    let activeSemesters = [];
    const active = props.semesters["hydra:member"].filter((elem) => elem.active);

    useEffect(() => {
        if (active.length) {
            activeSemesters = active.map((elem) => elem.id);
        } else if (props.semesters.length && "id" in props.semesters[0]) {
            activeSemesters = [props.semesters[0].id ?? null];
        }
        props.changeForm({ semester: activeSemesters }, "filter");
        props.loadingOn();
        axios.all([
            props.fetchCampuses(),
            props.fetchTrainingLevels(),
            props.fetchTrainingPrograms(),
            props.fetchCollegeYears(),
            props.fetchSemesters(),
            setInfo({ ...props, search: props.search }, "order", { semester: "desc" }, "filter", { semester: activeSemesters }),
        ]).then(() => props.loadingOff())
            .catch(() => props.loadingOff());
    }, []);

    const header = {
        title: "Kiiratkozások",
        breadcrumbs: {
            "reports": "Riportok",
            "terminations": "Kiiratkozások"
        }
    }

    const filterForm = {
        submitaction: (filter) => {
            filterStorageSave(filterStorageKey, filter);
            setInfo(props, "filter", filter)
        },
        defaultFormAction: () => {
            if (active.length) {
                activeSemesters = active.map((elem) => elem.id);
            } else if (props.semesters.length && "id" in props.semesters[0]) {
                activeSemesters = [props.semesters[0].id ?? null];
            }
            props.defaultForm("filter");
            props.changeForm({ semester: activeSemesters }, "filter");
            filterStorageSave(filterStorageKey, { semester: activeSemesters });
            setInfo(props, "filter", { semester: activeSemesters });
        },
        form: filter,
        changeform: props.changeForm
    }

    const downloadExportAction = () => {
        let date = new Date().toISOString().split('T')[0];
        let fileName = `Kiiratkozasi_riport_${date}.xlsx`;
        props.addNotification("info", "Fájl letöltése folyamatban...");
        props.downloadExport(filter, fileName)
            .then(() => props.addNotification("success", "Fájl letöltése sikeres!"))
            .catch(() => props.addNotification("error", "Hiba lépett fel a fájl letöltése közben."));
    }

    const table = {
        columnDefs: [
            { headerName: "Sorszám", field: "displayId" },
            { headerName: "Szemeszter", field: "semester" },
            { headerName: "Folyamatstátusz", field: "status" },
            { headerName: "Vezetéknév", field: "lastName" },
            { headerName: "Keresztnév", field: "firstName" },
            { headerName: "E-mail cím", field: "email" },
            { headerName: "Állandó lakcím", field: "address", minWidth: 400, sortable: false },
            { headerName: "Telefonszám", field: "phoneNumber" },
            { headerName: "Képzési szint", field: "trainingLevel" },
            { headerName: "Képzési program", field: "trainingProgram", minWidth: 300 },
            { headerName: "Évfolyam", field: "collegeYear" },
            { headerName: "Státusz", field: "membershipStatus" },
            { headerName: "Campus", field: "campus" },
            { headerName: "Épület", field: "building" },
            { headerName: "Szint", field: "floor" },
            { headerName: "Szoba", field: "room" },
            { headerName: "Kiköltözés oka", field: "reasonOfTermination" },
            { headerName: "Kiköltözés várható dátuma", field: "terminationDate" },
            { headerName: "Adományozási megállapodás", field: "donatingDeposit" },
            { headerName: "Adományozási hozzájárulás", field: "consentToUseData" },
            { headerName: "Név", field: "fullName" },
            { headerName: "Bankszámlaszám", field: "bankAccountNumberForDepositRefund" },
            { headerName: "Szobatakarítás", field: "roomCleaningStatus" },
            { headerName: "Szobakulcs", field: "roomKeyStatus" },
            { headerName: "Konyhaszekrény kulcs", field: "kitchenKeyStatus" },
            { headerName: "Üzemeltető megjegyzése", field: "operatorComment" },
            { headerName: "Pénzügy megjegyzése", field: "financialComment" },
            { headerName: "Tárgy 1", field: "item1" },
            { headerName: "Tárgy 2", field: "item2" },
            { headerName: "Tárgy 3", field: "item3" },
            { headerName: "Tárgy 4", field: "item4" },
            { headerName: "Tárgy 5", field: "item5" },
            { headerName: "Tárgy 6", field: "item6" },
            { headerName: "Tárgy 7", field: "item7" },
            { headerName: "Tárgy 8", field: "item8" },
            { headerName: "Tárgy 9", field: "item9" },
            { headerName: "Tárgy 10", field: "item10" },
        ].filter(column => fields?.includes(column.field)),
        rowData: data,
        frameworkComponents: {
            nameCellRenderer: MotherNameCellRenderer,
        },
        minWidth: 200,
        isPaginated: true,
        showPagination: false,
        download: true,
        onClick: downloadExportAction,
        searchfunc: props.search,
        info: tableInfo,
        setInfo: props.setInfo,
    }

    return (
        <ListPageTemplate
            table={table}
            filter={filterForm}
            header={header}
        >
            <TextField
                label="E-mail cím"
                name="email"
                value={filter.email || ""}
                format={{ xs: 12, md: 4 }}
            />
            <TextField
                label="Vezetéknév"
                name="lastName"
                value={filter.lastName || ""}
                format={{ xs: 12, md: 4 }}
            />
            <TextField
                label="Keresztnév"
                name="firstName"
                value={filter.firstName || ""}
                format={{ xs: 12, md: 4 }}
            />
            <Select
                selectLabel="Campus"
                name="campus"
                value={filter.campus || []}
                optionList={makeOptionsFromID(campuses) || []}
                format={{ xs: 12, md: 4 }}
                multiple={true}
            />
            <Select
                selectLabel="Képzési szint"
                name="trainingLevel"
                value={filter.trainingLevel || []}
                optionList={makeOptionsFromID(trainingLevels) || []}
                format={{ xs: 12, md: 4 }}
                multiple={true}
            />
            <Select
                selectLabel="Képzési program"
                name="trainingProgram"
                value={filter.trainingProgram || []}
                optionList={makeOptionsFromID(trainingPrograms) || []}
                format={{ xs: 12, md: 4 }}
                multiple={true}
            />
            <Select
                selectLabel="Évfolyam"
                name="collegeYear"
                value={filter.collegeYear || []}
                optionList={makeOptionsFromID(collegeYears) || []}
                format={{ xs: 12, md: 4 }}
                multiple={true}
            />
            <Select
                selectLabel="Szemeszter"
                name="semester"
                value={filter.semester || []}
                optionList={makeOptionsFromID(semesters) || []}
                format={{ xs: 12, md: 4 }}
                multiple={true}
            />
            <Select
                selectLabel="Folyamatstátusz"
                name="status"
                value={filter.status || []}
                optionList={options.processStatusOptionsTermination}
                format={{ xs: 12, md: 4 }}
                multiple={true}
            />
            <Select
                selectLabel="Szemeszter státusz"
                name="semesterStatus"
                value={filter.semesterStatus || []}
                optionList={options.semesterStatusOptions}
                format={{ xs: 12, md: 6 }}
                multiple={true}
            />
            <Select
                selectLabel="Aktuális státusz"
                name="currentMembershipStatus"
                value={filter.currentMembershipStatus || []}
                optionList={options.statusOptions}
                format={{ xs: 12, md: 6 }}
                multiple={true}
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, fields, filterStorageKey, tableInfo } = state.terminationsList;
    const campuses = state.campuses.data;
    const trainingLevels = state.trainingLevels.data;
    const trainingPrograms = state.trainingPrograms.data;
    const collegeYears = state.collegeYears.data;
    const semesters = state.semesters.data;
    return { filter, data, fields, filterStorageKey, tableInfo, campuses, trainingLevels, trainingPrograms, collegeYears, semesters };
}

const actionCreators = {
    setInfo: terminationsListActions.setInfo,
    changeForm: terminationsListActions.changeForm,
    downloadExport: terminationsListActions.downloadExport,
    defaultForm: terminationsListActions.defaultForm,
    search: terminationsListActions.filter,
    fetchCampuses: campusesActions.filter,
    fetchTrainingLevels: trainingLevelsActions.filter,
    fetchTrainingPrograms: trainingProgramsActions.fetch,
    fetchCollegeYears: collegeYearsActions.fetch,
    fetchSemesters: semestersActions.filter,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(TerminationsList);