import { templateChangeForm, templateDefaultForm, templateFilter } from "../..";
import moveoutListService from "../../../services/reports/moveout";
import downloadBlob from '../../../utils/downloadBlob';
import { types } from "./types";


function downloadExport(filter, fileName = 'export.xlsx') {
    return (dispatch) => {
        dispatch({ type: types.DOWNLOAD_EXPORT_REQUEST });
        return moveoutListService.downloadExport(filter).then(
            (data) => {
                dispatch({ type: types.DOWNLOAD_EXPORT_SUCCESS, data });
                downloadBlob(data.data, fileName);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.DOWNLOAD_EXPORT_FAILURE, error });
                return Promise.reject(error);
            }
        )
    }
}

function setInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_INFO, key, value });
    };
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    downloadExport,
    filter: templateFilter(types, moveoutListService),
    setInfo
}
export default actions;