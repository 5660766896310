import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";

import { administration } from "./administration";
import { forms } from "./forms";
import { layout } from "./layout";
import { loading } from "./loading/reducer";
import { masterData } from "./masterData";
import { modules } from "./modules";
import { notification } from "./notification/reducer";
import { processes } from "./processes";

import loadingTypes from "./loading/types";
import notificationTypes from "./notification/types";
import { reports } from "./reports";

export const templateChangeForm = (types) => (form, name) => (dispatch) =>
    dispatch({ type: types.CHANGE_FORM, data: { form: form, name: name } });

export const templateDefaultForm = (types) => (name) => (dispatch) =>
    dispatch({ type: types.DEFAULT_FORM, name });

/**
 * Megadott service filter metódusát lehívó és naplózó függvénysablon.
 * @param {Object} types
 *              A reducer típusai
 * @param {string} service
 *              Service, aminek a filter metódusát kell meghívni
 * @param {Object} form
 *              Szűrő értéke
 * @returns {Promise} A művelet sikeressége szerinti Promise
 */
export const templateFilter = (types, service) => (form) => (dispatch) => {
    dispatch({ type: types.FETCH_REQUEST, form });
    dispatch({ type: loadingTypes.TABLE_LOADING_ON });
    return service.filter(form).then(
        (data) => {
            dispatch({ type: types.FETCH_SUCCESS, data });
            dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.FETCH_FAILURE, error });
            dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
            return Promise.reject(error);
        }
    );
};

/**
 * Megadott service fetch metódusát lehívó és naplózó függvénysablon.
 * @param {Object} types
 *              A reducer típusai
 * @param {string} service
 *              Service, aminek a fetch metódusát kell meghívni
 * @returns {Promise} A művelet sikeressége szerinti Promise
 */
export const templateFetch = (types, service) => () => (dispatch) => {
    dispatch({ type: types.FETCH_REQUEST });
    return service.fetch().then(
        (data) => {
            dispatch({ type: types.FETCH_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.FETCH_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

/**
 * Megadott service create metódusát lehívó és naplózó függvénysablon.
 * @param {Object} types
 *              A reducer típusai
 * @param {string} service
 *              Service, aminek a create metódusát kell meghívni
 * @param {Object} form
 *              Létrehozandó érték
 * @returns {Promise} A művelet sikeressége szerinti Promise
 */
export const templateCreate =
    (types, service) => (form, url, id) => (dispatch) => {
        dispatch({ type: types.CREATE_REQUEST });
        return service.create(form, id).then(
            (data) => {
                dispatch({ type: types.CREATE_SUCCESS });
                dispatch({ type: types.DEFAULT_FORM, name: "form" });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: { type: "success", msg: "Sikeres mentés" },
                });
                if (url) history.push(url);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.CREATE_FAILURE, error });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: {
                        type: "error",
                        msg: "Hiba lépett fel mentés közben",
                    },
                });
                return Promise.reject(error);
            }
        );
    };

/**
 * Megadott service get metódusát lehívó és naplózó függvénysablon.
 * @param {Object} types
 *              A reducer típusai
 * @param {string} service
 *              Service, aminek a get metódusát kell meghívni
 * @param {number} id
 *              Lekérendő elem azonosítója
 * @returns {Promise} A művelet sikeressége szerinti Promise
 */
export const templateGet = (types, service) => (id) => (dispatch) => {
    dispatch({ type: types.GET_REQUEST });
    return service.get(id).then(
        (data) => {
            dispatch({ type: types.GET_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.GET_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

/**
 * Megadott service update metódusát lehívó és naplózó függvénysablon.
 * @param {Object} types
 *              A reducer típusai
 * @param {string} service
 *              Service, aminek az update metódusát kell meghívni
 * @param {Object} form
 *              Új érték
 * @param {number} id
 *              Változtatandó elem azonosítója
 * @returns {Promise} A művelet sikeressége szerinti Promise
 */
export const templateUpdate =
    (types, service) => (id, form, url) => (dispatch) => {
        dispatch({ type: types.UPDATE_REQUEST });
        return service.update(id, form).then(
            (data) => {
                dispatch({ type: types.UPDATE_SUCCESS });
                dispatch({ type: types.DEFAULT_FORM, name: "form" });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: { type: "success", msg: "Sikeres mentés" },
                });
                if (url) history.push(url);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.UPDATE_FAILURE, error });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: {
                        type: "error",
                        msg: "Hiba lépett fel mentés közben",
                    },
                });
                return Promise.reject(error);
            }
        );
    };

/**
 * Megadott service delete metódusát meghívó függvénysablon.
 * @param {Object} types
 *              A reducer típusai
 * @param {string} service
 *              Service, aminek a get metódusát kell meghívni
 * @param {number} id
 *              Törlendő elem azonosítója
 * @returns {Promise} A művelet sikeressége szerinti Promise
 */
export const templateDelete = (types, service) => (id) => (dispatch) => {
    dispatch({ type: types.DELETE_REQUEST });
    return service.delete(id).then(
        (data) => {
            dispatch({ type: types.DELETE_SUCCESS, data });
            dispatch({
                type: notificationTypes.ADD_NOTIFICATION,
                data: { type: "success", msg: "Sikeres törlés" },
            });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.DELETE_FAILURE, error });
            dispatch({
                type: notificationTypes.ADD_NOTIFICATION,
                data: { type: "error", msg: "Hiba lépett fel törlés közben" },
            });
            return Promise.reject(error);
        }
    );
};

export const history = createBrowserHistory();
const loggerMiddleware = createLogger();

const rootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        login: modules.login,
        translations: administration.translations,
        notification,
        emailTemplates: administration.emailTemplates,
        emailLog: administration.emailLog,
        systemLog: administration.systemLog,
        users: masterData.users,
        campuses: masterData.campuses,
        semesters: masterData.semesters,
        trainingLevels: masterData.trainingLevels,
        trainingPrograms: masterData.trainingPrograms,
        enrollment: processes.enrollment,
        studentAllocationPreference: processes.studentAllocationPreference,
        roomLayout: forms.roomLayout,
        students: masterData.students,
        universities: masterData.universities,
        documents: masterData.documents,
        buildings: masterData.buildings,
        collegeYears: masterData.collegeYears,
        floors: masterData.floors,
        rooms: masterData.rooms,
        items: masterData.items,
        main: modules.main,
        activeProcesses: processes.activeProcesses,
        closedProcesses: processes.closedProcesses,
        loading,
        permissions: administration.permissions,
        newProcess: processes.newProcess,
        approval: processes.approval,
        financialControl: processes.financialControl,
        operationalControl: processes.operationalControl,
        campusSelector: modules.campusSelector,
        relocationSingle: processes.relocationSingle,
        relocationMultiple: processes.relocationMultiple,
        relocationStudentFilling: processes.relocationStudentFilling,
        relocationFinancialControl: processes.relocationFinancialControl,
        relocationMoveOutForm: processes.relocationMoveOutForm,
        relocationOperatorRoomTakeOver:
            processes.relocationOperatorRoomTakeOver,
        relocationStudentRoomTakeOver: processes.relocationStudentRoomTakeOver,
        relocationApproveRoomTakeOver: processes.relocationApproveRoomTakeOver,
        roomAcceptanceReport: processes.roomAcceptanceReport,
        studentAllocationPreferenceForm:
            processes.studentAllocationPreferenceForm,
        termination: processes.termination,
        terminationStudentDeclaration: processes.terminationStudentDeclaration,
        terminationOperatorCheck: processes.terminationOperatorCheck,
        terminationFinancialCheck: processes.terminationFinancialCheck,
        moveOut: processes.moveOut,
        roomApproval: processes.roomApproval,
        roomAllocationList: processes.roomAllocationList,
        studentAllocationPreferenceList:
            reports.studentAllocationPreferenceList,
        studentsList: reports.studentsList,
        relocationsList: reports.relocationsList,
        terminationsList: reports.terminationsList,
        moveoutList: reports.moveoutList,
        switchUser: layout.switchUser,
        changePassword: layout.changePassword,
    });

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    rootReducer(history),
    process.env.NODE_ENV !== "production"
        ? composeEnhancers(
              applyMiddleware(
                  routerMiddleware(history),
                  thunkMiddleware,
                  loggerMiddleware
              )
          )
        : composeEnhancers(
              applyMiddleware(routerMiddleware(history), thunkMiddleware)
          )
);
