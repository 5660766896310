import { Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import { memo } from "react";
import colors from "../../../styles/colors";
import Dialog from "../../Common/Dialog";
import SubmitButton from "../../Common/SubmitButton";
import { kitchenKeyOptions } from "../Termination/FinancialCheck";

const useStyles = makeStyles(() => ({
    submit: {
        backgroundColor: colors.new,
        "&:hover": {
            backgroundColor: colors.newHover,
        },
    },
    title: {
        fontSize: "large",
        fontWeight: "bold",
        color: colors.primaryBlue,
    },
    container: {
        background: "rgba(0,0,0,0.1)",
    },
    textContainer: {
        padding: "8px 16px",
        margin: "0px 0px 16px 0px",
    },
    text: {
        fontSize: "1rem",
        margin: "1rem 0",
        textAlign: "justify",
    },
}));

const ApproveRoomTakeOverDialog = memo(function ApproveRoomTakeOverDialog(
    props
) {
    const { handleApprove, form, roomCleaningOptions, roomKeyOptions } = props;
    const classes = useStyles();

    return (
        <Dialog
            title="Szobaátvétel jóváhagyása"
            opener={
                <SubmitButton
                    className={classes.submit}
                    type="button"
                    fullWidth
                >
                    Jóváhagyás
                </SubmitButton>
            }
            action={
                <SubmitButton onClick={handleApprove}>Jóváhagyás</SubmitButton>
            }
            cancelText="Mégse"
        >
            <Typography>
                <Paper className={classes.container} elevation={4}>
                    <Grid
                        container
                        className={classes.textContainer}
                        justify="center"
                    >
                        <Grid item xs={12} container justify="center">
                            <div className={classes.title}>
                                Biztosan jóváhagyod a szobaátvételt?
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.text}>
                                <strong>A szoba adatai:</strong>
                            </div>
                            <div className={classes.text}>
                                Campus:{" "}
                                {
                                    form?.oldStudentAllocation?.room?.floor
                                        ?.building?.campus?.name
                                }{" "}
                                <br />
                                Épület:{" "}
                                {
                                    form?.oldStudentAllocation?.room?.floor
                                        ?.building?.name
                                }{" "}
                                <br />
                                Szint:{" "}
                                {
                                    form?.oldStudentAllocation?.room?.floor
                                        ?.name
                                }{" "}
                                <br />
                                Szoba: {
                                    form?.oldStudentAllocation?.room?.name
                                }{" "}
                                <br />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.text}>
                                <strong>Tárgyak és takarítás:</strong>
                            </div>
                            <div className={classes.text}>
                                {form?.oldStudentAllocation?.room?.items?.map(
                                    (item) => {
                                        return (
                                            <div>
                                                {item?.name}:{" "}
                                                {form.items?.[item?.id] || item.amount || 0}/
                                                {item?.amount}
                                            </div>
                                        );
                                    }
                                )}
                                Szobatakarítás:{" "}
                                {roomCleaningOptions.find(
                                    (x) =>
                                        x.value === form["roomCleaningStatus"]
                                )?.label || "Nem szükséges"}{" "}
                                <br />
                                Szobakulcs:{" "}
                                {roomKeyOptions.find(
                                    (x) => x.value === form["roomKeyStatus"]
                                )?.label || "Rendben"}
                                <br />
                                Konyhaszekrény kulcs:{" "}
                                {kitchenKeyOptions.find(
                                    (x) => x.value === form["kitchenKeyStatus"]
                                )?.label || "Rendben"}
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </Typography>
        </Dialog>
    );
});

export default ApproveRoomTakeOverDialog;
