import { KeyboardDatePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors.js";
import { useField, useFormikContext } from "formik";

const CssKeyboardDatePicker = withStyles({
    root: {
        "& label.Mui-focused": {
            color: colors.primaryBlue,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors.primaryBlue,
        },
        "& .MuiInput-underline:before": {
            borderBottomColor: colors.primaryBlue,
        },
        "& .MuiInput-underline:hover": {
            borderBottomColor: colors.primaryBlue,
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: colors.primaryBlue,
            },
            "&:hover fieldset": {
                borderColor: colors.primaryBlue,
            },
            "&.Mui-focused fieldset": {
                borderColor: colors.primaryBlue,
            },
        },
    },
})(KeyboardDatePicker);

const CssKeyboardDateTimePicker = withStyles({
    root: {
        "& label.Mui-focused": {
            color: colors.primaryBlue,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors.primaryBlue,
        },
        "& .MuiInput-underline:before": {
            borderBottomColor: colors.primaryBlue,
        },
        "& .MuiInput-underline:hover": {
            borderBottomColor: colors.primaryBlue,
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: colors.primaryBlue,
            },
            "&:hover fieldset": {
                borderColor: colors.primaryBlue,
            },
            "&.Mui-focused fieldset": {
                borderColor: colors.primaryBlue,
            },
        },
        "& .MuiTabs-indicator": {
            backgroundColor: colors.primaryBlue,
        },
    },
})(KeyboardDateTimePicker);

export default function FormikDatePicker(props) {
    const { name, functionToChild, formNameToChild, formToChild, showTime = false, ...rest } = props;
    const { setFieldTouched, setFieldValue, initialErrors } = useFormikContext();
    const [field, meta] = useField(name);
    const errorText = meta.error && meta.touched ? meta.error : "";

    const convertToHyphenFormat = (date) => {
        if (!date || !moment(date).isValid()) {
            return null;
        }
        return showTime ? moment(date).toISOString() : moment(date).format("YYYY-MM-DD");
    };

    const handleBlur = (e) => {
        delete initialErrors[field.name];
        field.onBlur(e);
    };

    const PickerComponent = showTime ? CssKeyboardDateTimePicker : CssKeyboardDatePicker;

    return (
        <PickerComponent
            {...field}
            {...rest}
            autoOk
            value={props.value || null}
            variant="inline"
            inputVariant="outlined"
            hideTabs={showTime ? true : false}
            format={showTime ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD"}
            minutesStep={showTime ? 15 : 1}
            invalidDateMessage={`Hibás formátum`}
            sx={{
                '& .MuiTabs-indicator': {
                    backgroundColor: 'blue'
                }
            }}
            maxDateMessage={`Nem lehet nagyobb, mint 2300.01.01.`}
            minDateMessage={`Nem lehet kisebb, mint 1800.01.01.`}
            minDate={moment("1800-01-01")}
            maxDate={moment("2300-01-01")}
            onBlur={handleBlur}
            InputLabelProps={{ shrink: true }}
            onChange={(date) => {
                const formattedDate = convertToHyphenFormat(date);

                if (!formattedDate) {
                    return;
                }

                functionToChild(
                    {
                        ...formToChild,
                        ...(props.name.includes(".")
                            ? {
                                  [props.name.split(".")[0]]: {
                                      ...formToChild[props.name.split(".")[0]],
                                      [props.name.split(".")[1]]: formattedDate,
                                  },
                              }
                            : { [props.name]: formattedDate }),
                    },
                    formNameToChild
                );
                setFieldValue(field.name, formattedDate);
                setFieldTouched(field.name, true, true);
            }}
            onChangeRaw={() => {
                setFieldTouched(field.name, true, true);
            }}
            fullWidth
            size="small"
            helperText={errorText}
            error={!!errorText}
        />
    );
}
