export const types = {
    CHANGE_FORM: "@@moveout_list/CHANGE_FORM",
    DEFAULT_FORM: "@@moveout_list/DEFAULT_FORM",

    FETCH_REQUEST: "@@moveout_list/FETCH_REQUEST",
    FETCH_SUCCESS: "@@moveout_list/FETCH_SUCCESS",
    FETCH_FAILURE: "@@moveout_list/FETCH_FAILURE",

    DOWNLOAD_EXPORT_REQUEST: "@@moveout_list/DOWNLOAD_EXPORT_REQUEST",
    DOWNLOAD_EXPORT_SUCCESS: "@@moveout_list/DOWNLOAD_EXPORT_SUCCESS",
    DOWNLOAD_EXPORT_FAILURE: "@@moveout_list/DOWNLOAD_EXPORT_FAILURE",

    FETCH_SET_INFO: "@@moveout_list/FETCH_SET_INFO",
}