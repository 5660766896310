
import { types } from "./types";

const initialState = {
    filterStorageKey: "reports.relocationsList",
    filter: {},
    form: {},
    data: [],
    file: null,
    tableInfo: {
        filter: {},
        order: {},
        page: 1,
        itemsPerPage: 25,
        metadata: {}
    }
}


export function relocationsList(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                [action.name]: initialState[action.name],
                filter: initialState.filter,
                form: initialState.form,
            };
        case types.FETCH_SET_INFO:
            return {
                ...state,
                tableInfo: {
                    ...state.tableInfo,
                    [action.key]: action.value,
                },
            };
        case types.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                tableInfo: {
                    ...state.tableInfo,
                    metadata: {
                        'allCount': action?.data?.data?.metadata?.allCount,
                        'filteredCount': action?.data?.data?.metadata?.filteredCount,
                        'lastPage': action?.data?.data?.metadata?.lastPage,
                        'page': action?.data?.data?.metadata?.page,
                        'itemsPerPage': state.tableInfo.itemsPerPage,
                    }
                },
                fields: action.data.data.fields,
                data: action.data.data.views,
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };
        case types.DOWNLOAD_EXPORT_REQUEST:
            return {
                ...state,
            };
        case types.DOWNLOAD_EXPORT_SUCCESS:
            return {
                ...state,
                exportFile: action.data.data,
            };
        case types.DOWNLOAD_EXPORT_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        default: 
            return {
                ...state
            }
    }
}
